import { LOGIN, LOGOUT, USERROLEACCOUNT,TEACHERSCHOOLLIST, ADD_STUDENT_LISTING } from "../Types/Logintypes"


const initialState = {
    userRoleAccountData: undefined,
    access_token: undefined,
    studentList: undefined,
    teacherSchoolListData: undefined
}

const reducer = (state = initialState, action) => {
    const modifiedState = { ...state }
    switch (action.type) {
        case LOGIN:

            return {
                ...modifiedState,
                access_token: action.payload.access_token
            }


        case USERROLEACCOUNT:
            return {
                ...modifiedState,
                userRoleAccountData: action.payload.userRoleAccountData
            }

        case TEACHERSCHOOLLIST:
            return {
                ...modifiedState,
                teacherSchoolListData: action.payload.teacherSchoolListData
            }

        case ADD_STUDENT_LISTING:
            return {
                ...modifiedState,
                studentList: action.payload.studentList
            };


        case LOGOUT:
            return {
                access_token: undefined,
                userRoleAccountData: undefined
            }
        default: { }

    }

    return modifiedState;
}

export default reducer; 